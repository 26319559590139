/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { request, IPagination } from "xiangxin-tool";
import {
  SegmentListParamsInterface,
  SegmentInterface,
  GetOrderInterface,
  BatchGetOrderInterface,
  BatchPutOrderInterface,
  PartPutOrderInterface,
  PathInterface,
  EditTImeInterface,
  IPriceBoxParmas,
} from "@/interface/segment";
import { TransportColumnInterface } from "@/interface/transport";
import {
  BoxListParamsInterface,
  BoxListPaginationInterface,
} from "@/interface/box";
import { OrderListParamsInterface, CostNameInterface } from "@/interface/order";
import {
  OrderListInterface,
  SegmentItemInterface,
} from "xiangxin-element/dist/types/interface";
import {
  CouponListParamsInterface,
  CouponListInterface,
} from "@/interface/driver";
import { BmapDistanceParamsInterface } from "@/interface/system";

// 购物车分段列表
export const StepListApi = (
  params: SegmentListParamsInterface
): Promise<IPagination<SegmentItemInterface>> =>
  request({
    url: "/dispatch/segment",
    params,
  });

// 分段 -- 添加分段
export const CutSegmentApi = (
  params: SegmentInterface
): Promise<{ id: string | undefined }> =>
  request({
    url: "/dispatch/segment/split",
    data: params,
    method: "POST",
  });
// 分段 -- 分段修改
export const EditSegmentApi = (
  params: SegmentInterface
): Promise<{ id: string | undefined }> =>
  request({
    url: "/dispatch/segment",
    data: params,
    method: "PUT",
  });
// 分段 -- 分段移除
export const DeleteSegmentApi = (
  params: SegmentInterface
): Promise<{ id: string | undefined }> =>
  request({
    url: "/dispatch/segment/delete",
    data: params,
    method: "POST",
  });
// 分段 -- 分段前置数据
export const BeforeSegmentApi = (
  params: Record<string, string | number> = {}
): Promise<Record<string, any>> =>
  request({
    url: "/dispatch/box/segment/collection",
    params,
  });

// 获取最早提箱时间
export const GetStartSegTimeApi = (params: {
  box_id: string;
  qi_end_at?: string;
}): Promise<{ qi_start_at: string }> =>
  request({
    url: "/order/box/segment/zuizao/at",
    params,
  });
// 批量分段 -- 根据箱子id获取产装信息
export const GetProductTimeApi = (params: { box_ids: string | undefined }) =>
  request({
    url: "/order/box/products",
    params,
  });
// 批量分段 -- 批量分段
export const SplitBatchApi = (params: SegmentInterface): Promise<any> =>
  request({
    url: "/dispatch/segment/splitBatch",
    data: params,
    method: "POST",
  });

// 箱列表
export const BoxListApi = (
  data: BoxListParamsInterface
): Promise<BoxListPaginationInterface> =>
  request({
    method: "POST",
    url: "/dispatch/box",
    data,
  });

export const BoxListExportApi = (
  data: BoxListParamsInterface,
  params: any
): Promise<any> => {
  return request({
    method: "POST",
    url: "/dispatch/box",
    data,
    params,
    responseType: "blob",
  });
};

// 费用列表
export const GetExpensesApi = (): Promise<any> =>
  request({
    url: "/dispatch/segment/expenses",
    // params,
  });
// 费用项目配置 -- 添加费用列表（暂时不用了）
export const AddSegExpensesApi = (params: { ids: string }): Promise<any> =>
  request({
    url: "/dispatch/segment/expenses",
    data: params,
    method: "POST",
  });

// 费用项目配置 -- 删除项目 （暂时不用了）
export const DeleteExpenses = (params: { id: string }): Promise<any> =>
  request({
    url: "/dispatch/segment/expenses/del",
    data: params,
    method: "POST",
  });
// 费用项目配置 -- 获取下拉的项目名称（暂时不用了）
export const GetFinancialData = (params: CostNameInterface): Promise<any> =>
  request({
    url: "/financial/data",
    params,
  });
// 订单列表
export const OrderListApi = (
  params: OrderListParamsInterface
): Promise<BoxListPaginationInterface> =>
  request({
    url: "/dispatch/order/segment/collection",
    params,
  });

// 分段 --- 路线地址
export const PathSearchApi = (params: {
  scope: string | number | undefined;
}): Promise<PathInterface[]> =>
  request({
    url: "/stock/manager/in_state",
    params,
  });

// 派单司机列表
// scope  港口
// box_id  箱子id
export const AssignDriverApi = (params: any = {}): Promise<any> =>
  request({
    url: "/dispatch/assign/driver",
    params,
  });
// 派单 -- 车队列表
export const AssignConvoyApi = (params: any = {}): Promise<any> =>
  request({
    url: "/dispatch/assign/convoy",
    params,
  });
// 派单 -- 分组列表
export const GetGroupApi = (params: any = {}): Promise<any> =>
  request({
    url: "/driver/group",
    params,
  });
// 派单 -- 批量派单
export const BatchSendOrderApi = (params: BatchPutOrderInterface) =>
  request({
    url: "/dispatch/segment/combination/batch",
    data: params,
    method: "POST",
  });
// 派单列表 -- 编辑时间
export const EditTimeApi = (params: EditTImeInterface) =>
  request({
    url: "/dispatch/segment/at",
    data: params,
    method: "POST",
  });

// 接单统计
export const OrderStatisicsListApi = (params: any) =>
  request({
    url: "/dispatch/driver/stat/box",
    params,
  });
// 接单统计/详情 列表
export const OrderStatisicsDetailApi = (params: {
  driver_id: number;
  page?: number;
  status: number;
}) =>
  request({
    url: "/driver/order/new/",
    params,
  });

// 卡券列表
export const CouponListApi = (
  params: CouponListParamsInterface
): Promise<IPagination<CouponListInterface>> =>
  request({
    url: "/dispatch/driver/stat/coupon",
    params,
  });
// 抢单 - 购物车未选择司机或车队时
export const DispatchSegmentGrabApi = (
  params: GetOrderInterface
): Promise<any> =>
  request({
    url: "/dispatch/segment/grab",
    data: params,
    method: "POST",
  });
// 抢单 - 批量抢单
export const BatchGetOrderApi = (
  params: BatchGetOrderInterface
): Promise<any> =>
  request({
    url: "/dispatch/segment/grab/batch",
    data: params,
    method: "POST",
  });
// 抢单 - 部分抢单
export const PartGetOrderApi = (params: PartPutOrderInterface): Promise<any> =>
  request({
    url: "/dispatch/segment/grab/node",
    data: params,
    method: "POST",
  });

// 路线抢单 - 查看报价
export const GetPriceBoxApi = (params: IPriceBoxParmas): Promise<any> =>
  request({
    url: "/dispatch/price/box",
    params,
  });

// 派单（购物车 - 选择司机时）
export const DispatchSegmentCombinationApi = (
  params: GetOrderInterface
): Promise<IPagination<OrderListInterface>> =>
  request({
    url: "/dispatch/segment/combination",
    data: params,
    method: "POST",
  });

// 取消抢单
export const CancelGetOrderApi = (params: {
  dsph_order_id: string | number | "";
}): Promise<any> =>
  request({
    url: "/dispatch/grab/cancel",
    data: params,
    method: "POST",
  });

// 订单列表修改备注
export const OrderListEditRemarkApi = (remark: string, dsph_order_id: string) =>
  request({
    url: "/dispatch/order/segment/collection",
    data: { remark, dsph_order_id },
    method: "put",
  });
// 放单策略 -- 获取列表/刷新
export const AssignGrabRuleApi = (params: {
  scope?: string | number | undefined;
}): Promise<any> =>
  request({
    url: "/dispatch/grab/rule",
    params,
  });

// 放单策略 -- 保存
export const SaveGrabRuleApi = (params: Record<string, any>): Promise<any> =>
  request({
    url: "/dispatch/grab/rule",
    data: params,
    method: "POST",
  });

// 箱列表 - 预留
export const DispatchAssignBookApi = (params: {
  box_id: number[];
  type: 1 | 2; // 1 预留，2 取消预留
}): Promise<any> =>
  request({
    url: "/dispatch/assign/book",
    data: params,
    method: "put",
  });
// 箱列表 - 颜色标记
export const DispatchAssignColorApi = (params: {
  box_id: number[];
  color_id: number[];
  type: 1 | 2; // 1 加，2 减
}): Promise<any> =>
  request({
    url: "/dispatch/assign/color/operate",
    data: params,
    method: "post",
  });

// 查看物流
export const LookLogisticsApi = (params: {
  id: string | number | undefined;
}): Promise<any> =>
  request({
    url: `/order/item/box/${params.id}/line`,
  });

// 购物车 获取箱子段信息,返回值是数组但是只应该有一条数据
export const GetBoxSegmentDetailApi = (
  box_segment_id: number | string,
  node_type: number | string
): Promise<SegmentItemInterface[]> =>
  request({
    url: `/dispatch/segment/info`,
    params: { box_segment_id, node_type },
  });
// 获取司机信息
export const GetDriverInfoApi = (driverId: number): Promise<any> =>
  request({
    url: `/driver/item/${driverId}`,
  });
// 获取箱子产装详情
export const GetBoxProducsApi = (box_ids: number): Promise<any> =>
  request({
    url: `/order/box/products/batch`,
    params: {
      box_ids,
    },
  });
// 段的操作 -- 开始
export const StartOperationApi = (params: Record<string, any>) =>
  request({
    url: "/order/operate/start",
    data: params,
    method: "POST",
  });

// 段的操作 -- 到达
export const ArriveOperationApi = (params: Record<string, any>) =>
  request({
    url: "/order/operate/daoda",
    data: params,
    method: "POST",
  });
// 段的操作 -- 装货完成
export const ShipmentOperationApi = (params: Record<string, any>) =>
  request({
    url: "/order/operate/zhuanghuo",
    data: params,
    method: "POST",
  });
// 段的操作 -- 段的完成
export const SegmentOperationApi = (params: Record<string, any>) =>
  request({
    url: "/order/operate/finish",
    data: params,
    method: "POST",
  });
// 调度订单详情
export const DsipatchOrderDetailApi = (id: string) =>
  request({
    url: `/dispatch/order/segment/collection/${id}`,
  });
// 箱列表 修改备注
export const BoxListEditRemarkApi = (box_id: number, box_remark: string) =>
  request({
    url: `/dispatch/box/update/box_remark`,
    method: "POST",
    data: {
      box_id,
      box_remark,
    },
  });
// 箱列表 位置下拉框
export const BoxListPositionApi = (): Promise<Record<string, string>> =>
  request({
    url: `/dispatch/box/get/position`,
  });
// 箱列表 位置修改
export const BoxListPositionEditApi = (
  box_id: number,
  box_position_type: number
) =>
  request({
    url: `/dispatch/box/update/box_position`,
    method: "post",
    data: {
      box_id,
      box_position_type,
    },
  });
// 箱列表 分派司机
export const BoxRelationDriverApi = (data: {
  driver_id: number | string;
  box_segment_id: number | string;
  car_id: number | string;
  node_type: number | string;
}) =>
  request({
    url: `/dispatch/box/relation/driver`,
    method: "post",
    data,
  });
// 箱列表 获取装卸地址距离
export const GetBmapDistance = (params: BmapDistanceParamsInterface) =>
  request({
    url: `/g/dp/bmap/distance`,
    params,
  });
// 批量抢派单费用查询
export const CollectionMoneyApi = (params: { box_segment_ids: string }) =>
  request({
    url: `/dispatch/box/segment/collection/zheng/money`,
    params,
  });
// 组合类型列表
export const CollectionGroupTypeApi = () =>
  request({
    url: `/dispatch/order/segment/collection/group/type`,
  });

// 运力统计 表头 列表
export const TransportColumnApi = (
  scope: string | number
): Promise<{ list: TransportColumnInterface[] }> =>
  request({
    url: `/dispatch/statistics/field`,
    params: { scope },
  });

// 运力统计 表头 删除
export const TransportColumnDelApi = (id: number) =>
  request({
    url: `/dispatch/statistics/field/${id}`,
    method: "DELETE",
  });
// 运力统计 表头 添加 address_level 1一级地址 2二级地址 3三级地址
export const TransportColumnCreateApi = (
  address_level: 1 | 2 | 3,
  scope: string,
  address_id: string | number
) =>
  request({
    url: "/dispatch/statistics/field",
    method: "put",
    data: {
      address_level,
      scope,
      address_id: `${address_id}`,
    },
  });
// 运力统计 列表
export const TransportListApi = (params: {
  scope: number | string;
  date: string;
}): Promise<{ header: any; list: any }> =>
  request({
    url: `/dispatch/statistics/list`,
    params,
  });
// 运力统计 日期 列表
export const TransportDateListApi = (params: {
  scope: number | string;
  date: string;
  act?: 1 | 2; //0默认 1左 2右
}): Promise<{ list: any[] }> =>
  request({
    url: `/dispatch/statistics/date/list`,
    params,
  });

// 省市区 一二级地址 type -1 即获取全部
export const ProvincesAndCitiesApi = (): Promise<{
  data: { id: number; name: string; p_id: number }[];
}> =>
  request({
    url: "/common/address/find",
    params: { type: -1 },
  });
// 省市区下属地区 id: 父级ID
export const CountyListApi = (id: number) =>
  request({
    url: `/common/address/find/${id}`,
    params: { type: -1 },
  });

// 运力统计 运力分组详情
export const TransportGroupApi = (params: {
  scope: number | string;
  date: string;
  group_id: number;
  role_type: number;
  keyword?: string;
  page: number;
  page_size: number;
}): Promise<{ header: any; list: any }> =>
  request({
    url: `/dispatch/statistics/group/detail`,
    params,
  });

export const getWhereAssignFilter = (params?: {
  worker_type?: number;
  is_batch?: number;
}): Promise<
  { id: number; name: string }[] | Record<string, { id: number; name: string }>
> => {
  return request({
    url: "/dispatch/filter/where/assign",
    params,
  });
};
export const getWhereGrabFilter = (): Promise<
  { id: number; name: string }[] | Record<string, { id: number; name: string }>
> => {
  return request({
    url: "/dispatch/filter/where/grab",
  });
};
