import { createApp, Directive } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/styles/index.scss";
import ElementPlus from "@/plugins/element";
import * as directives from "@/directives";
import { ComponentLibrary } from "@xiangxin-wc/vue3";
import { requestConfig } from "xiangxin-tool";
import { ElMessage } from "element-plus";
requestConfig({
  message: ElMessage,
});

const app = createApp(App);

app.use(ComponentLibrary).use(ElementPlus).use(store).use(router).mount("#app");
app.config.globalProperties.$ELEMENT_PLUS_MAX = {
  scrollContainer: document.getElementsByClassName("app-layout-content")[0],
};
Object.keys(directives).forEach((directive) => {
  app.directive(
    directive,
    (directives as Record<string, Directive>)[directive]
  );
});
